<template>
	<div>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
			:slot_table_list="['operation']" :submit_preprocessing="submit_preprocessing" ref="list" :rowKey="'id'">
			<template slot="operation" slot-scope="data">
				<span>
					<a @click="edit(data.record)">编辑</a>
				</span>
				<a-divider type="vertical" v-if='data.record.flag' />
				
			</template>
		</TableList>
		<EditPop @handleSubmit="submit_company" :width="550"  class='edit_pop1' ref='form_company' :form_data="form_company"
			:visible.sync="visible_data_company">

		</EditPop>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import SelectUser from "@/components/SelectUser";
	import {
		authInfo
	} from '@/api/system'
	import TransferTree from "@/components/TransferTree";
	import {
		getSpecialList,setSpecialApprovalt,getSpecialDetail
	} from "@/api/evection";
	import {
		// getUserList
	} from "@/api/personnel";

	const columns = [{
			title: "ID",
			dataIndex: "id"
		},
		{
			title: "规则名称",
			dataIndex: "title"
		},
		// {
		// 	title: "适用人数",
		// 	dataIndex: "user_num"
		// },
		// {
		// 	title: "标准类型",
		// 	dataIndex: "type_name"
		// },
		{
			title: "最后更新时间",
			dataIndex: "update_time"
		},

		{
			title: "操作",
			dataIndex: "operation",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];
	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList,
			SelectUser,
			TransferTree
		},
		data() {
			return {
                info_data:{},
				treeData: [],
				treeDataValue: [],
				all_person: [],
				default_person: [],
				visible_data_company: false,
				form_company: {
					title: "免签批规则",
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list: [
                        {
                            type: "text",
                            name: "title",
                            title: "规则类型",
                            disabled: true,
                            options: {
                                
                            },
                        },
						{
						    type: "tree-select",
						    name: "department_id",
						    title: "适用部门",
						    options: {},
						    treeData: [],
						    multiple: true
						},
						{
						    type: "tree-select",
						    name: "role_id",
						    title: "适用岗位",
						    options: {},
						    treeData: [],
						    multiple: true
						},
						// {
						//     type: "select",
						//     name: "role_id",
						//     title: "适用岗位",
						//     mode: "default",
						//     options: {},
						//     list: [],
                        //      mode: 'multiple'
						// },


					]
				},
				config: this.$config,
				get_table_list: getSpecialList,
				submit_preprocessing: {
					array_to_string: ['department_id', 'group_id','role_id']
				},
				columns,
				selectedRowKeys: [],
				loading: false,
				visible: false,
				rule_user:'',
				form_data_seo: {
					list: [
						

					],
					...this.$config.form_data_seo
				},
	
			};
		},


		async created() {
			this.$method.get_department().then(res => {
                let temp_arr = [{
                    key:0,
                    value:0,
                    title:'全部',
                }]
			    this.form_company.list.forEach(item => {
			        if (item.name == "department_id") {
			            item.treeData = [...temp_arr,...res];
			        }
			    });
			});
			this.$method.get_role_tree().then(res => {
				let temp_arr = [{
                    key:0,
                    value:0,
                    title:'全部',
                }]
			    this.form_company.list.forEach(item => {
			        if (item.name == "role_id") {
			            item.treeData = [...temp_arr,...res];
			        }
			    });
			});
			// this.$method.get_role().then(res => {
			//     this.form_company.list.forEach(item => {
            //         this.$config.status_all[0].key = 0;
			//         if (item.name == "role_id") {
			//             item.list = [...this.$config.status_all, ...res];
			//         }
			//     });
			// });
		},
		methods: {
			
			submit_company(data) {
                let info = {
                    type:this.info_data.type,
                    id:this.info_data.id,
                    department_id:data.values.department_id.join(','),
                    role_id:data.values.role_id.join(','),
                    title:data.values.title
                }
				setSpecialApprovalt({
					data: info,
					info: true
				}).then(res => {
					this.visible_data_company = false;
                     this.$refs.list.get_list();
                    
				})
			},
			get_info(id){
                getSpecialDetail({data:{id:id}}).then(res=>{
                    this.info_data = res.data.list;
                    let arr = []
                     let arr2 = []
                     if( res.data.list.role_id){
                         res.data.list.role_id.split(',').forEach(item=>{
                             arr.push(Number(item))
                         })
                     }
                  if(res.data.list.department_id){
                      res.data.list.department_id.split(',').forEach(item=>{
                          arr2.push(Number(item))
                      })
                  }
                  
                     this.$refs.form_company.setFieldsValue({
                         department_id: arr2
                     });
                     this.$refs.form_company.setFieldsValue({
                         role_id: arr
                     });
                     this.$refs.form_company.setFieldsValue({
                         title:res.data.list.title
                     });
                })
            },
			
			edit(key) {
              
                this.visible_data_company= true;
                this.info_data = key
                this.get_info(key.id)
			},
		}
	};
</script>

<style lang="less" >
	@import url("../../assets/less/app.less");

	.left_btn_box {
		flex: inherit;

		p {
			margin: 5px 0px;
		}
	}

	.edit_pop1 {
		.ant-col-19 {
			width: 77%;
		}

		.ant-form-item-label {
			width: 22%;
		}

		.ant-form-item {
			margin-bottom: 0px;
		}

		.tips_p {
			margin-top: -30px;
			margin-left: 175px;
			line-height: 25px;
			width: 70%;
		}
		.info_icon {
			margin-right: 5px;
		}
	}
	.free_title {
		margin-left: 90px;
		color: #000;
	}
	.free_tree {
		margin-left: 80px;
	}
	.info_tips {
		
	}
</style>
